import { localNotifications } from '$lib/localNotifications';
import { initialiseUserPreferences } from '$lib/utilities/userPreferences/initialiseUserPreferences';
import type { HandleClientError } from '@sveltejs/kit';
import 'cordova-plugin-purchase';
import 'cordova-plugin-purchase/www/store.d';

import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

// initialiseSentry();

dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

initialiseUserPreferences();
localNotifications.migrateNotifications();

export const handleError: HandleClientError = async ({ message }) => {
	return {
		message,
	};
};
