import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62')
];

export const server_loads = [];

export const dictionary = {
		"/": [10],
		"/_dev_iap_": [40],
		"/_dev_": [11,[2],[3]],
		"/_dev_/RecipeCoverTitle": [12,[2],[3]],
		"/_dev_/ToggleSlideButtons": [13,[2],[3]],
		"/_dev_/app-store-screenshot": [14],
		"/_dev_/badges": [15,[2],[3]],
		"/_dev_/border": [16,[2],[3]],
		"/_dev_/button-text": [18,[2],[3]],
		"/_dev_/button": [17,[2],[3]],
		"/_dev_/calendar": [19,[2],[3]],
		"/_dev_/cross": [20,[2],[3]],
		"/_dev_/dateSlider": [21,[2],[3]],
		"/_dev_/dot": [22,[2],[3]],
		"/_dev_/flex": [23,[2],[3]],
		"/_dev_/grid": [24,[2],[3]],
		"/_dev_/icon": [25,[2],[3]],
		"/_dev_/input": [26,[2],[3]],
		"/_dev_/margin": [27,[2],[3]],
		"/_dev_/modal": [28,[2],[3]],
		"/_dev_/padding": [29,[2],[3]],
		"/_dev_/plane": [30,[2],[3]],
		"/_dev_/quantity": [31,[2],[3]],
		"/_dev_/shadow": [32,[2],[3]],
		"/_dev_/streak": [33,[2],[3]],
		"/_dev_/test-local-notifications": [34,[2],[3]],
		"/_dev_/testimonials": [35,[2],[3]],
		"/_dev_/text-area": [37,[2],[3]],
		"/_dev_/textButton": [38,[2],[3]],
		"/_dev_/text": [36,[2],[3]],
		"/_dev_/topNavigation": [39,[2],[3]],
		"/download": [41],
		"/goal/new": [42,[4]],
		"/home": [43,[5]],
		"/home/accomplishments": [44,[5,6]],
		"/home/accomplishments/achievements": [45,[5,6]],
		"/home/accomplishments/badges": [46,[5,6]],
		"/home/calendar": [47,[5]],
		"/home/facts": [48,[5]],
		"/home/goal": [49,[5]],
		"/home/resources": [50,[5,7]],
		"/home/resources/ideas": [51,[5,7]],
		"/home/resources/information": [52,[5,7,8]],
		"/home/resources/information/food-categories": [53,[5,7,8]],
		"/home/resources/testimonials": [54,[5,7]],
		"/home/settings": [55,[5]],
		"/home/settings/changelog": [56,[5,9]],
		"/home/settings/error-monitoring": [57,[5]],
		"/home/settings/languages": [58,[5]],
		"/home/settings/notifications": [59,[5]],
		"/home/subscription": [60,[5]],
		"/introduction": [61],
		"/privacy-policy": [62]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';